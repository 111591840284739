import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Louis",
  avatarImage: "/images/favicon1.png",
  content:
    "Je suis passionné de nouvelles technologies depuis ma plus tendre enfance. J'ai toujours voulu connaître le fonctionnement des objets, j'ai donc jeté mon dévolu sur le numérique. Dans l'entreprenariat depuis mes 16 printemps.",
};

const progressData = [
  {
    id: 1,
    title: "Réseaux",
    percantage: 70,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Virtualisation",
    percantage: 75,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Commerce",
    percantage: 55,
    progressColor: "#6C6CE5",
  },
];

const counterData = [
  {
    id: 1,
    title: "Projets démarrés",
    count: 7,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Coca consommées",
    count: 5670,
    icon: "icon-cup",
  },
  {
    id: 3,
    title: "Connexion établie ?",
    count: 504,
    icon: "icon-people",
  },
  {
    id: 4,
    title: "Projets terminés",
    count: 4,
    icon: "icon-badge",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="À propos de moi" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-sm-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
