import React from "react";
import Pagetitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
 {
    id: 1,
    title: "BTS SIO",
    years: "2021 - 2022",
    content:
      "Arrêt en cours d'année du BTS",
  },
  {
    id: 2,
    title: "Baccalauréat professionnel GA",
    years: "2018 - 2021",
    content:
      "Obtention du baccalauréat national mention bien.",
  },
    {
    id: 3,
    title: "Brevet des collèges",
    years: "2018",
    content:
      "Obtention du Diplôme national du brevet mention bien.",
  },
];

const experienceData = [
  {
    id: 3,
    title: "Administateur Système - Support N2",
    years: "rentrée 2024",
    content:
      "CDI en tant qu'Administateur Système - Support N2 chez NETSYST",
  },
  {
    id: 1,
    title: "Auto-entrepreneur",
    years: "2019 - Maintenant",
    content:
      "Immatriculation de la société individuelle Monsieur Louis ADAM.",
  },
  {
    id: 2,
    title: "Assistant mecanicien",
    years: "2019",
    content:
      "Maintenance mécanique de générateur électrique chez SAD",
  },
];

function Experiences() {
  return (
    <section id="experience">
      <div className="container">
        <Pagetitle title="Parcours" />
        <div className="row">
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} education={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                <Timeline key={experience.id} education={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
