import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";

function Contact() {
  
  return (
    <section id="contact">
      <div className="container">
        <Pagetitle title="Contact" />

        <div className="row">
          <div className="col-md-8">
            <div className="contact-info">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3>Contactez moi !</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p>
                  Vous n'aimez pas les formulaires ? Moi non plus ! Envoyez-moi un{" "}
                  <a href="mailto:contact@louis-adam.fr">mail</a>. 👋
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
